// const url = 'http://localhost:4000';
const url = 'https://wapps-api.ithouz.com';
const version = '1.0';

const api = {
    login: url + '/users/signin',
    users: url + '/users',
    userProfile: url + '/user/profile/image',
    companyLogo: url + '/company/logo',
    policyMasterIcon: url + '/policy/master/icon',
    policyCategoryIcon: url + '/policy/category/icon',
    policyRiderIcon: url + '/policy/rider/icon',
    riderReferenceURL: url + '/rider/reference',
    userPolicyDocument: url +'/policy/document'
}

export  { api, url, version };